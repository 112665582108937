<template>
  <div
    v-if="type === 'label'"
    class="custom-label"
    :class="[
      { 'custom-label__disabled': disabled || showLoader },
      { 'custom-label__full-width': isFullWidth },
    ]"
    :style="specificWidth ? { width: specificWidth } : {}"
    @click="handleClick"
  >
    <SfLink
      v-if="link"
      :link="link"
      :class="[`${customLabelClass}`]"
      :target="target"
    >
      <LoadingDots v-if="showLoader" />
      <slot v-else />
    </SfLink>
    <span v-else :class="`${customLabelClass}`">
      <LoadingDots v-if="showLoader" />
      <slot v-else />
      <component
        v-if="hasIcon && type !== 'icon'"
        :is="theme === 'grey' ? 'IconArrowBlack' : 'IconArrowWhite'"
        class="custom-label__icon"
      />
    </span>
  </div>
  <SfButton
    v-else
    :disabled="disabled || showLoader"
    :type="type"
    :link="link"
    :aria-label="ariaLabel"
    :class="[
      `custom-button ${theme}`,
      `custom-button ${size}`,
      { 'custom-button__transparent': isTransparent },
      { 'custom-button__transparent-on-hover': isTransparentOnHover },
      { 'custom-button__disabled': disabled || showLoader },
      { 'custom-button__full-width': isFullWidth },
      { 'custom-button__icon': hasIcon },
    ]"
    @click="handleClick"
    :style="specificWidth ? { width: specificWidth } : {}"
    :target="target"
  >
    <LoadingDots v-if="showLoader" />
    <slot v-else />
    <component v-if="hasIcon" :is="icon" class="custom-button__icon" />
    <p v-if="iconText !== ''" class="custom-button__icon__text">
      {{ iconText }}
    </p>
  </SfButton>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { AddToCartIcon, AddToWishlistIcon } from '~/components/General/Icons';
import { SfButton, SfLink } from '@storefront-ui/vue';
import LoadingDots from '~/components/General/LoadingDots.vue';

export default defineComponent({
  name: 'CustomButton',
  components: {
    SfLink,
    SfButton,
    LoadingDots,
    AddToCartIcon,
    AddToWishlistIcon,
  },
  props: {
    theme: {
      type: String,
      default: 'orange',
      validator(value) {
        return ['orange', 'grey', 'black', 'white'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['small', 'normal', 'big'].includes(value);
      },
    },
    customLabelClass: {
      type: String,
      default: 'text-15-light-white-bold',
    },
    iconText: {
      type: String,
      default: '',
    },
    isTransparent: {
      type: Boolean,
      default: false,
    },
    isTransparentOnHover: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) =>
        ['button', 'submit', 'reset', 'label', 'icon'].includes(value),
    },
    link: {
      type: String,
      default: '',
    },
    ariaLabel: {
      type: String,
      default: 'button',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    specificWidth: {
      type: [String, undefined],
      default: undefined,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      if (!props.disabled && props.link === '') emit('click');
    };
    return { handleClick };
  },
});
</script>

<style lang="scss" scoped>
.custom-button {
  @include normal-cta;
  &.small {
    @include small-cta;
  }
  &.big {
    @include big-cta;
  }
  &.orange {
    @include cta-orange;
    &:hover,
    &:active {
      .custom-button__icon {
        ::v-deep path {
          fill: var(--c-white);
        }
        &__text {
          color: var(--c-white);
        }
      }
      // @include grey-cta-active;
    }
    .custom-button__icon {
      ::v-deep path {
        fill: var(--c-white);
      }
      &__text {
        color: var(--c-white);
      }
    }
    .custom-button__disabled {
      color: var(--c-white);
    }
  }
  &.grey {
    @include cta-grey;
    &:hover,
    &:active {
      .custom-button__icon {
        ::v-deep path {
          fill: var(--c-white);
        }
        &__text {
          color: var(--c-white);
        }
      }
      @include white-cta-active;
    }
    .custom-button__icon {
      ::v-deep path {
        fill: var(--c-primary-light);
      }
      &__text {
        color: var(--c-primary-light);
      }
    }
    .custom-button__disabled {
      color: var(--c-primary-lightest);
    }
  }
  &.black {
    @include cta-black;
    &:hover,
    &:active {
      .custom-button__icon {
        ::v-deep path {
          fill: var(--c-white);
        }
        &__text {
          color: var(--c-white);
        }
      }
      @include black-cta-active;
    }
    .custom-button__icon {
      ::v-deep path {
        fill: var(--c-white);
      }
      &__text {
        color: var(--c-white);
      }
    }
  }
  &.white {
    @include cta-white;
    border: 0.5px solid var(--c-primary-lightest);
    &:hover,
    &:active {
      .custom-button__icon {
        ::v-deep path {
          fill: var(--c-white);
        }
        &__text {
          color: var(--c-black);
        }
      }
      @include white-cta-active;
    }
    .custom-button__icon {
      ::v-deep path {
        fill: var(--c-primary);
      }
      &__text {
        color: var(--c-primary);
      }
    }
  }
  &__disabled {
    opacity: 50%;
  }
  &__icon {
    display: flex;
    column-gap: 10px;
  }
  &__full-width {
    width: 100%;
    max-width: 100%;
  }
}
</style>

<style lang="scss">
.custom-button {
  .sf-loader {
    &__overlay {
      background: none;
    }
  }
}
</style>
