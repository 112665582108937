<template>
  <div
    class="custom-textarea"
    :class="[disabled && 'custom-textarea-disabled']"
  >
    <ValidationProvider
      :rules="validateRule"
      v-slot="{ errors }"
      class="custom-textarea__container"
    >
      <SfTextarea
        :id="textareaId"
        :value="value"
        :label="label"
        :name="name"
        :cols="cols"
        :rows="rows"
        :maxlength="maxlength"
        :minlength="minlength"
        :wrap="wrap"
        :readonly="readonly"
        :valid="valid"
        :error-message="errorMessage"
        :required="required"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleChange"
        @blur="handleBlur"
      />
      <div v-if="errors[0]" class="custom-textarea__error">
        {{ $t(errors[0]) }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import { required, alpha_num } from 'vee-validate/dist/rules';
import { useI18n } from '~/helpers/hooks/usei18n';
import { SfTextarea } from '@storefront-ui/vue';

extend('alpha_num', {
  ...alpha_num,
  message: 'May only contain alpha-numeric characters',
});
extend('required', {
  ...required,
  message: 'Required field',
});

export default defineComponent({
  name: 'CustomTextarea',
  components: {
    ValidationProvider,
    SfTextarea,
  },
  model: {
    prop: 'value',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    cols: {
      type: [String, Number],
      default: '',
    },
    rows: {
      type: [String, Number],
      default: '10',
    },
    maxlength: {
      type: [String, Number],
      default: null,
    },
    minlength: {
      type: [String, Number],
      default: null,
    },
    wrap: {
      type: String,
      default: 'soft',
      validator: (value) => {
        return ['soft', 'hard', 'off'].includes(value);
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textareaId: {
      type: String,
      required: true,
    },
    validateRule: {
      type: [Boolean, String],
      default: '',
    },
  },
  setup(props, { emit }) {
    const handleChange = (value) => {
      emit('onChange', value);
      emit('input', value);
    };

    const handleBlur = (value) => {
      emit('onBlur', value);
    };

    const trans = useI18n();

    extend('min', {
      validate(v, arguments_) {
        return v.length >= arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be longer than than {0} characters`, [
          arguments_.length,
        ]);
      },
    });
    extend('max', {
      validate(v, arguments_) {
        return v.length < arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be shorter than {0} characters`, [
          arguments_.length,
        ]);
      },
    });

    return {
      handleChange,
      handleBlur,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-textarea {
  &__container {
    position: relative;
  }
  &__error {
    @include paragraph-regular;
    padding: var(--input-error-padding);
    color: var(--c-red-error);
  }
}
</style>

<style lang="scss">
.custom-textarea {
  .sf-textarea {
    textarea {
      width: 100%;
      @include border-radius-standard;
      @include paragraph-regular;
      &:focus-visible {
        outline: none;
      }
      &:focus ~ .sf-textarea__label {
        color: var(--c-dark-gray);
        transform: translate3d(0, -0.6875rem, 0);
      }
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px var(--c-dark-gray);
        @include border-radius-standard;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--c-black);
        @include border-radius-standard;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: var(--c-black);
      }
    }
    &__label {
      @include paragraph-regular;
      transform: translate3d(0, 0, 0);
      top: 1rem;
      padding: 0 1.5rem;
    }
    &__error-message {
      display: none;
    }
    &.sf-textarea--has-text {
      .sf-textarea__label {
        color: var(--c-dark-gray);
        transform: translate3d(0, -0.6875rem, 0);
      }
    }
  }
}
</style>
