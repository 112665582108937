<!-- eslint-disable vue/component-name-in-template-casing -->
<template>
  <div class="custom-date-picker">
    <div class="custom-date-picker__title" v-if="title">{{ title }}</div>
    <date-picker
      v-model="date"
      value-type="format"
      format="DD/MM/YYYY"
      @change="handleChange"
      :placeholder="placeholder"
      :disabled-date="disableDates"
      :range="range"
      confirm
      :confirm-text="$t('OK')"
      :class="{ 'has-error': validateRule && !date }"
    ></date-picker>
    <div class="custom-date-picker__error" v-if="validateRule && !date">
      {{ $t('Required field') }}
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/it';
import 'vue2-datepicker/index.css';

export default defineComponent({
  name: 'CustomDatePicker',
  components: {
    DatePicker,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    range: {
      type: Boolean,
      default: false,
    },
    inputDate: {
      type: [String, Array],
      default: null,
    },
    disableDates: {
      type: Function,
      default: null,
    },
    validateRule: {
      type: String,
      default: '',
      validator(value) {
        return ['required'].includes(value);
      },
    },
  },
  setup(props, { emit }) {
    const date = ref(props.inputDate);

    watch(
      () => props.inputDate,
      (newDate) => {
        date.value = newDate;
      }
    );

    const handleChange = (d) => {
      emit('change', d);
    };

    return {
      date,
      handleChange,
    };
  },
});
</script>

<style lang="scss">
.mx-datepicker {
  width: 100%;
  &-popup {
    .cell.active {
      background-color: var(--c-primary);
    }
  }
  .mx-input {
    height: var(--form-element-mobile-height);
    @include grey-input;
  }
  &.has-error {
    .mx-input {
      border: 1px solid var(--c-red-error);
    }
  }
}
.custom-date-picker {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  &__title {
    @include paragraph-regular;
    text-transform: uppercase;
  }
  &__error {
    @include paragraph-regular;
    color: var(--c-red-error);
  }
}
@include from-desktop-min {
  .mx-datepicker {
    .mx-input {
      height: var(--form-element-desktop-height);
    }
  }
}
</style>
