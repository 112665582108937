<template>
  <h2 class="divider" v-if="text">
    <span class="divider__text">{{ $t(text) }}</span>
  </h2>
  <hr v-else />
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Divider',
  props: {
    text: {
      type: [String, null],
      default: null,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.divider {
  width: 100%;
  margin: 1rem 0;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  @include heading-h2;
  font-size: 4vw;
  &:before,
  &:after {
    background-color: var(--c-white);
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &:before {
    right: 0.5em;
    margin-left: -50%;
  }
  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}
@include from-desktop-min {
  .divider {
    width: 100%;
    margin: 1rem 0;
    overflow: hidden;
    text-align: center;
    @include heading-h2;
    &:before,
    &:after {
      background-color: var(--c-black);
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }
    &:before {
      right: 0.5em;
      margin-left: -50%;
    }
    &:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
}
</style>
